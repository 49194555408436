import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";

export const BoxList = styled.div`
  background-color: #000;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RoadMapTitle = styled.div`
  color: var(--accent-text);
  font-family: DelaGothicOne;
  text-align: center;
  font-size: 1rem;
  @media (min-width: 500px) {
    font-size: 2rem;
  }
  font-weight: 500;
  line-height: 1.6;
`;
export const RoadMapContent = styled.div`
  color: var(--accent-text);
  font-family: DelaGothicOne;
  font-size: 0.7rem;
  @media (min-width: 500px) {
    font-size: 1rem;
  }
  @media (min-width: 779px) {
    font-size: 1.2rem;
  }
  padding-bottom: 10%;

  font-weight: 500;
  line-height: 1.6;
`;

export const StyledRoadmap = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const RoadmapTitle = styled.p`
  color: var(--accent-text);
  font-family: DelaGothicOne;

  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledRoadmapName = styled.p`
  color: var(--primary-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Roadmap() {
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <RoadmapTitle
              id="roadmap"
              style={{
                textAlign: "center",
              }}
            >
              {"ROADMAP"}
            </RoadmapTitle>
            <s.SpacerLarge />
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <RoadMapTitle>{"Now"}</RoadMapTitle>
              <RoadMapContent>
                {"Launch NEO STACKEY"}
                <br />
                {"Total Supply:1,650pcs"}
                <br />
                {"Presale:0.03ETH"}
                <br />
                {"Public Sale:0.05ETH"}
                <br />
              </RoadMapContent>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <RoadMapTitle>{"Expantion"}</RoadMapTitle>
              <RoadMapContent>
                {"Launch Next Project"}
                <br />
                {"Collaboration with 12 IP"}
                <br />
                {"『START Dream Collection』"}
                <br />
              </RoadMapContent>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <RoadMapTitle>{"Event"}</RoadMapTitle>
              <RoadMapContent>
                {"Planning a big"}
                <br />
                {"NFT EXPO in TOKYO"}
                <br />
              </RoadMapContent>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <RoadMapTitle>{"Metaverse"}</RoadMapTitle>
              <RoadMapContent>
                {"Development of"}
                <br />
                {"『START HOUSE』"}
                <br />
                {"in Metaverse"}
                <br />
              </RoadMapContent>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Roadmap;
