import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";

export const BoxList = styled.div`
  /* background-color: #000; */
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledMember = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: "none";
`;

export const MemberTitle = styled.p`
  font-size: 2rem;
  color: var(--accent-text);
  text-decoration: "none";
  font-family: DelaGothicOne;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledMemberName = styled.p`
  color: var(--primary-text);
  font-size: 1rem;
  @media (min-width: 500px) {
    font-size: 1.2rem;
  }
  @media (min-width: 751px) {
    font-size: 1.4rem;
  }
  @media (min-width: 980px) {
    font-size: 2rem;
  }
  font-family: DelaGothicOne;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Member() {
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <MemberTitle
              id="member"
              style={{
                textAlign: "center",
              }}
            >
              {"MEMBER"}
            </MemberTitle>
            <s.SpacerLarge />
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // backgroundColor: "black",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledMember alt={"logo"} src={"/config/images/kousei.jpeg"} />
              <StyledLink href="https://twitter.com/KzcHArt" target="_blank">
                <StyledMemberName> KOUSEI</StyledMemberName>
              </StyledLink>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // backgroundColor: "black",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledMember alt={"logo"} src={"/config/images/oni.png"} />
              <StyledLink
                href="https://twitter.com/oni_cyan_nft"
                target={"_blank"}
              >
                <StyledMemberName> ONIちゃん</StyledMemberName>
              </StyledLink>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // backgroundColor: "black",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledMember alt={"logo"} src={"/config/images/shunbow.png"} />
              <StyledLink
                href="https://twitter.com/shunbow_nft"
                target="_blank"
              >
                <StyledMemberName> SHUNBOW</StyledMemberName>
              </StyledLink>
            </Box>
          </Grid>
          <Grid xs={6} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                // backgroundColor: "black",
                // backgroundImage: 'url(/config/images/kousei.jpeg)',
                // backgroundSize: 250,
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledMember alt={"logo"} src={"/config/images/fricklik.png"} />
              <StyledLink
                href="https://twitter.com/freakyyyy_frick"
                target={"_blank"}
              >
                <StyledMemberName> FRICKLIK</StyledMemberName>
              </StyledLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Member;
