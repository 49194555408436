const initialState = {
  loading: false,
  balanceOf: false,
  maxMintsPerAL_0: 1,
  maxMintsPerAL_1: 1,
  maxMintsPerAL_2: 2,
  phaseId: 0,
  error: false,
  errorMsg: "",
};

const dataSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATASUB_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_SUCCESS":
      return {
        ...state,
        loading: false,
        balanceOf: action.payload.balanceOf,
        maxMintsPerAL_0: action.payload.maxMintsPerAL_0,
        maxMintsPerAL_1: action.payload.maxMintsPerAL_1,
        maxMintsPerAL_2: action.payload.maxMintsPerAL_2,
        // maxMintsPerPS: action.payload.maxMintsPerPS,
        deployer: action.payload.deployer,
        phaseId: action.payload.phaseId,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataSubReducer;
