import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";
export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledButton = styled.button`
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-family: DelaGothicOne;

  border: none;
  background-color: black;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 90%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

function Header() {
  return (
    <AppBar
      position="static"
      style={{
        textAlign: "center",
        backgroundColor: "rgba(63,81,181,0.4)",
      }}
    >
      {/* <Toolbar>
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={6} sm={3}>
              <AnchorLink href="#about">
                <StyledButton>ABOUT</StyledButton>
              </AnchorLink>
            </Grid>
            <Grid xs={6} sm={3}>
              <AnchorLink href="#roadmap">
                <StyledButton>ROADMAP</StyledButton>
              </AnchorLink>
            </Grid>
            <Grid xs={6} sm={3}>
              <AnchorLink href="#member">
                <StyledButton>MEMBER</StyledButton>
              </AnchorLink>
            </Grid>
            <Grid xs={6} sm={3}>
              <AnchorLink href="#faq">
                <StyledButton>Q&A</StyledButton>
              </AnchorLink>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar> */}
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <StyledKeyVisual alt={"logo"} src={"/config/images/header.jpeg"} />
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default Header;
