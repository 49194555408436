import React, { useEffect, useState, useRef } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import * as s from "./../styles/globalStyles";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: none;
`;

export const Invisible = styled.button`
  display: none;
`;

export const StyledButton = styled.button`
  margin: 5px;
  border-radius: 10%;
  font-family: DelaGothicOne;
  position: fixed;
  right: 8px;
  bottom: 8px;
  border: none;
  background-color: black;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  /* width: 10%; */
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-animation: mintanime 6s linear 0s infinite;
  animation: mintanime 6s linear 0s infinite;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const LinksBox = styled.div`
  display: inline-table;
  flex: 1;
  float: left;
  width: 100%;
  padding: 10px 0;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

export const LogoImage = styled.img`
  width: 30px;
`;

export const LinksDiv = styled.div`
  display: inline-table;
  flex: 1;
  width: 20%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;
export const MintDiv = styled.div`
  display: grid;
  flex: 1;
  width: 20%;
  float: left;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
`;

function Footer({ config }) {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    window.scrollY > 500 ? setIsVisible(true) : setIsVisible(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <AppBar position="static">
      <Toolbar
        style={{
          backgroundImage: "url(/config/images/cnwLogoWHNNM.png)",
          backgroundSize: "auto 100%",
          backgroundColor: "rgba(63,81,181,0.5)",
          backgroundBlendMode: "color",
        }}
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={5} sm={9}></Grid>
            <Grid xs={7} sm={3}>
              <LinksBox>
                <LinksDiv>
                  <StyledLink target={"_blank"} href={config.MARKETPLACE_LINK}>
                    <LogoImage src="/config/images/opensea.svg" alt="Opensea" />
                  </StyledLink>
                </LinksDiv>
                <LinksDiv>
                  <StyledLink
                    target={"_blank"}
                    href={"https://discord.gg/startjpn"}
                  >
                    <LogoImage src="/config/images/discord.svg" alt="discord" />
                  </StyledLink>
                </LinksDiv>
                <LinksDiv>
                  <StyledLink
                    target={"_blank"}
                    href={"https://twitter.com/KzcHArt"}
                  >
                    <LogoImage src="/config/images/twitter.svg" alt="twitter" />
                  </StyledLink>
                </LinksDiv>
                <MintDiv>
                  <AnchorLink href="#mint">
                    {isVisible ? (
                      <StyledButton>MINT</StyledButton>
                    ) : (
                      <Invisible>MINT</Invisible>
                    )}
                  </AnchorLink>
                </MintDiv>
              </LinksBox>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
