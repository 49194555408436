import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";

export const BoxList = styled.div`
  background-color: #000;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: "underline";
`;

export const TextDescription = styled.p`
  color: white;
  text-align: center;
  font-size: 0.9rem;
  @media (min-width: 500px) {
    font-size: 1.2rem;
  }
  line-height: 1.6;
`;
export const TextLink = styled.p`
  color: white;
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledAbout = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const AboutTitle = styled.p`
  color: var(--accent-text);
  font-family: DelaGothicOne;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledAboutName = styled.p`
  color: var(--primary-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

function About() {
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <AboutTitle
              id="about"
              style={{
                textAlign: "center",
              }}
            >
              {"ABOUT"}
            </AboutTitle>
          </Grid>
          <Grid xs={12} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                backgroundColor: "black",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledAbout alt={"logo"} src={"/config/images/kousei.jpeg"} />
            </Box>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                backgroundColor: "black",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <s.SpacerLarge />
              <TextDescription>
                {"Neo Samurai Monkeysの姉妹IPとして"}
                <br />
                {"『 NEO STACKEY 』が誕生"}
                <br />
                <br />
                {"Web3の世界に新たな未来を切り拓くことを使命とし"}
                <br />
                {"生意気な目で常に未来を見つめている"}
                <br />
                <br />
                {"カプセル状の頭、武器、ファッションなどで"}
                <br />
                {"多くのクリエイターとコラボしている"}
              </TextDescription>
              <s.SpacerLarge />
            </Box>
          </Grid>
          <Grid xs={12} sm={3}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                backgroundColor: "black",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            >
              <StyledAbout alt={"logo"} src={"/config/images/bhm.jpeg"} />
            </Box>
          </Grid>
          <Grid xs={12} sm={12}>
            <s.SpacerLarge />

            <s.Container flex={1} jc={"center"} ai={"center"}>
              <TextDescription>
                {"より詳しいことはコチラから👇"}
              </TextDescription>
              <StyledLink
                href="https://onikonradio.com/neo-stackey.html"
                target={"_blank"}
              >
                『NEO SAMURAI MONKEYS』の姉妹IP
                <br class="sp" />
                『NEO STACKEY』とは？
                <br class="tablet" />
                プロジェクトの魅力に迫る！
              </StyledLink>
            </s.Container>
          </Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default About;
