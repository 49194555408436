// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 0)
        .call();
      // let maxMintsPerAL = 0;
      let maxMintsPerAL_0 = await store
        .getState()
        .blockchain.smartContract.methods.maxMintsPerAL(0)
        .call();
      let maxMintsPerAL_1 = await store
        .getState()
        .blockchain.smartContract.methods.maxMintsPerAL(1)
        .call();
      let maxMintsPerAL_2 = await store
        .getState()
        .blockchain.smartContract.methods.maxMintsPerAL(2)
        .call();
      let deployer = await store
        .getState()
        .blockchain.smartContract.methods.deployer()
        .call();
      // let deployer = "0x637d25D0769f747B2742A04d249802dA85395970";
      let phaseId = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseId()
        .call();
      // let maxMintsPerPS = await store
      //   .getState()
      //   .blockchain.smartContract.methods.maxMintsPerPS()
      //   .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSubSuccess({
          balanceOf,
          maxMintsPerAL_0,
          maxMintsPerAL_1,
          maxMintsPerAL_2,
          // maxMintsPerPS,
          deployer,
          phaseId,
          // cost,
        })
      );
    } catch (err) {
      console.dir(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
