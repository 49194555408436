import { AppBar, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "./../styles/globalStyles";
import React from "react";
import { isMobile } from "react-device-detect";

export const BoxList = styled.div`
  /* background-color: #000; */
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPartners = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  vertical-align: middle;
  /*コレ*/
  position: absolute;
  /*コレ*/
  top: 50%;
  /*コレ*/
  transform: translate(0, -50%);
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledLink = styled.a`
  color: "#FFF";
  text-decoration: "none";
`;

export const PartnersTitle = styled.p`
  font-size: 2rem;
  color: var(--accent-text);
  text-decoration: "none";
  font-family: DelaGothicOne;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledPartnersName = styled.p`
  color: var(--primary-text);
  font-size: 1rem;
  @media (min-width: 500px) {
    font-size: 1.2rem;
  }
  @media (min-width: 751px) {
    font-size: 1.4rem;
  }
  @media (min-width: 980px) {
    font-size: 2rem;
  }
  font-family: DelaGothicOne;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

function Partners() {
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            <PartnersTitle
              id="Partners"
              style={{
                textAlign: "center",
              }}
            >
              {"START Community Partners"}
            </PartnersTitle>
            <s.SpacerLarge />
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/nsm.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/swagga.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/asagi.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/flowerlolita.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/aiartdao.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/metakawaii.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/lag.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/ntp.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/vla.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/azito.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/spread.png"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/tma.png"}
              />
            </Box>
          </Grid>
          {isMobile ? (
            <></>
          ) : (
            <>
              <Grid xs={0} sm={1}>
                <Box
                  sx={{
                    paddingLeft: 0,
                    paddingTop: 15,
                    width: 1 / 1.25,
                    height: 100,
                    position: "relative",
                    mx: "auto",
                  }}
                ></Box>
              </Grid>
            </>
          )}

          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/spacecrocos.png"}
              />
            </Box>
          </Grid>

          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/raphy.png"}
              />
            </Box>
          </Grid>

          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/cngarden.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/clonexcn.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/jer.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/bitamen.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/batct.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/dudu.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/moonbird.jpeg"}
              />
            </Box>
          </Grid>
          <Grid xs={3} sm={1}>
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 15,
                width: 1 / 1.25,
                height: 100,
                position: "relative",
                mx: "auto",
              }}
            >
              <StyledPartners
                alt={"logo"}
                src={"/config/images/logos/ossan.png"}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Partners;
